
import {
  Component,
  Emit,
  Model,
  Prop,
  Vue,
  Watch
} from "vue-property-decorator";
import Rules from "@/app/modules/_global/classes/Rules";
import moment from "moment-timezone";

type Attachment = {
  blob: Blob;
  path: string;
};

type Expense = {
  id?: number;
  date?: string;
  expenses?: number;
  kilometers?: number;
  notes?: string;
};

@Component({
  components: {
    Alert: () => import("@/app/modules/_global/components/Alert.vue"),
    Attachments: () =>
      import("@/app/modules/expenses/components/Attachments.vue"),
    datepicker_: () =>
      import("@/app/modules/_global/components/elements/DatePicker.vue"),
    timepicker_: () =>
      import("@/app/modules/_global/components/elements/TimePicker.vue")
  }
})
export default class ExpenseFormComponent extends Vue {
  @Model("input", { type: Object }) readonly expense!: Expense;
  @Prop({ default: false }) readonly disabled?: boolean;
  @Prop(Object) readonly errors?: object;

  @Emit()
  input() {
    return this.formData;
  }

  @Watch("formData", { deep: true })
  onFormDataChanged() {
    this.input();
  }

  @Watch("errors", { deep: true })
  onErrorsChanged(errors) {
    this.localErrors = { ...errors };
  }

  valid: boolean = true as boolean;
  filled: boolean = false as boolean;
  passed: boolean = false as boolean;
  localErrors: object = {} as object;

  formData: Expense = {};
  attachments: Attachment[] = [];

  rules = {
    required: Rules.required,
    date: Rules.date
  };

  dateMax = moment().format("YYYY-MM-DD");
  dateMin = "";

  constructor() {
    super();

    this.formData = { ...this.expense };
    if (!this.expense.date) this.formData.date = moment().format("YYYY-MM-DD");
  }

  mounted() {
    moment.tz.setDefault();

    this.filledInput();
    this.setDateMin();
  }

  filledInput() {
    const km = this.formData["kilometers"];
    const chf = this.formData["expenses"];

    if (km || chf) this.filled = true;
    else this.filled = false;
  }

  setDateMin() {
    const now = moment();
    const limitDate = moment([now.year(), now.month(), 5]);
    const limitDatePassed = now.isAfter(limitDate);

    // Restriction: Employee should create the Expense report for previous month only until 4th (including) day of the current
    if (limitDatePassed) {
      // Only the current month is available
      this.dateMin = moment()
        .startOf("month")
        .format("YYYY-MM-DD");
    } else {
      // Current and previous months are available
      this.dateMin = moment()
        .subtract(1, "month")
        .startOf("month")
        .format("YYYY-MM-DD");
    }

    // Restriction: Employee should update the Expense report for previous month only until 4th day (including) of the current
    if (limitDatePassed) {
      // Editable only for the current month
      this.passed = moment()
        .startOf("month")
        .isAfter(moment(this.expense.date));
    } else {
      // Editable for previous and current months
      this.passed = moment()
        .subtract(1, "month")
        .startOf("month")
        .isAfter(moment(this.expense.date));
    }
  }

  attach(attachment: Attachment) {
    this.attachments.push(attachment);
  }

  removeAttachment(attachment: Attachment) {
    const index = this.attachments.indexOf(attachment);
    this.attachments.splice(index, 1);
  }
}
